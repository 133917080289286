













import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/functions';
import { v4 as uuidv4 } from 'uuid';
import { Place } from '@/models/place.model';

export default Vue.extend({
  name: 'PlaceSearch',
  props: {
    place: Object as () => Place,
    label: String
  },
  data() {
    return {
      places: this.place
        ? [{ text: this.place?.address, value: this.place?.googlePlaceId }]
        : [],
      placeSearch: null,
      selectedUserPlace: {
        text: this.place?.address,
        value: this.place?.googlePlaceId
      },
      loading: false,
      timer: 0,
      sessionToken: uuidv4()
    };
  },
  methods: {
    passData(place: Place) {
      this.$emit('placeSelected', place);
    },
    getPlace(text: string) {
      this.$store.state.isLoading = true;
      firebase
        .functions()
        .httpsCallable('getPlace')({
          text: text,
          sessionToken: this.sessionToken
        })
        .then(result => {
          this.loading = false;
          this.places = result.data.map((value: any) => {
            return {
              text: value.description,
              value: value.place_id
            };
          });
        });
    },
    getPlaceDetails(placeId: string) {
      firebase
        .functions()
        .httpsCallable('getPlaceDetails')({
          placeId: placeId
        })
        .then(result => {
          const place = {} as Place;
          console.log(result.data);

          place.googlePlaceId = result.data.place_id;
          place.address = result.data.formatted_address;
          if (result.data.rating) {
            place.rating = result.data.rating;
          }
          if (result.data.user_ratings_total) {
            place.numberOfRatings = result.data.user_ratings_total;
          }
          const city = result.data.address_components.filter((obj: any) => {
            if (obj.types.includes('locality')) {
              return true;
            }
            if (obj.types.includes('administrative_area_level_1')) {
              return true;
            }
            return false;
          })[0].long_name;
          const country = result.data.address_components.filter((obj: any) => {
            return obj.types.includes('country');
          })[0].long_name;
          place.city = city;
          place.country = country;
          place.geoPoint = new firebase.firestore.GeoPoint(
            result.data.geometry.location.lat,
            result.data.geometry.location.lng
          );
          console.log(place.geoPoint);
          this.$store.state.isLoading = false;
          this.passData(place);
        })
        .catch(error => {
          this.$store.state.isLoading = false;
          console.error(error);
        });
    },
    fetchEntriesDebounced(text: string) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getPlace(text);
      }, 500);
    },
    customFilter(item: any): string {
      return item;
    }
  },
  watch: {
    placeSearch(val) {
      if (val && val !== this.place?.address) {
        this.loading = true;
        this.fetchEntriesDebounced(val);
      }
    },
    selectedUserPlace(val) {
      this.sessionToken = uuidv4();
      if (val) {
        this.getPlaceDetails(val);
      }
    }
  }
});
